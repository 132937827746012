// import logo from "./logo.svg";
import "./App.css";
import "./assets/css/inline.css";
import Layout from "./Pages/Layout";
import Wiki from "./Pages/Wiki";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      {/* end main section */}
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="/wiki/:id" element={<Wiki />} />
        <Route path="/w/:id" element={<Wiki />} />
      </Routes>
    </div>
  );
}

export default App;
