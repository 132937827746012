import logoTekno from "../../src/assets/image/Logo.png.png";
import logoFtik from "../../src/assets/image/FTIK-Logo (1).png";
import logoFsip from "../../src/assets/image/FSIP-LOGO.png";
import logoFeb from "../../src/assets/image/FEB-Logo.png";
import logoYukbaca from "../../src/assets/image/yukbaca.png";
import logoSeputarBisnis from "../../src/assets/image/seputarbisnis.png";
import logoSeputerTeknologi from "../../src/assets/image/seputarteknologi.png";
import logoBelajarBahasa from "../../src/assets/image/belajarbahasa.png";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Wiki() {
  const params = useParams();

  const apiEndpoint =
    "https://ar.teknopedia.teknokrat.ac.id/API/api?website=ja.wikipedia.org/wiki/" +
    params.id +
    "&class=.mw-page-container";
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState();
  const [content, setContent] = useState();
  const [notFoud, setNotfound] = useState(false);
  useEffect(
    function () {
      async function getArtikel() {
        const request = await fetch(apiEndpoint);
        const response = await request.json();

        if (response.data_artikel == null) {
          document.title = "Artikel Not Found";
          return setNotfound(true);
        } else {
          // Replace kata 'Wikipedia' menjadi 'Teknopedia'
          const modifiedContent = await response.data_artikel.content
            .replace(/Wikipedia/g, "Teknopedia")
            .replace(/<a /g, '<a target="_self" rel="noreferrer"');
          // .replace(/<a\s+([^>]*?)href=/g, "<Link $1to=");
          const modifiedTitle = await response.data_artikel.title.replace(
            /Wikipedia/g,
            "Teknopedia"
          );

          console.log(modifiedTitle);
          setLoading(false);
          const contents = parse(modifiedContent);
          setContent(contents);
          document.title = modifiedTitle;
          setTitle(modifiedTitle);
        }
      }
      getArtikel();
    },
    [apiEndpoint]
  );

  if (notFoud) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h1>Artikel tidak ditemukan 😞</h1>
        <p>Sepertinya artikel yang kamu cari tidak ada.</p>
        <button
          onClick={() => window.history.back()}
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            backgroundColor: "#007BFF",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Kembali ke halaman sebelumnya
        </button>
      </div>
    );
  }
  return (
    <>
      {/* search field area */}
      <div className="searchfield" style={{ backgroundColor: "#243549" }}>
        <div className="container">
          <Link to="/">
            <img alt="logo teknokrat" src={logoTekno} />
          </Link>
        </div>
      </div>
      {/* end search field area */}
      {/* main section */}
      <main id="main" style={{ backgroundColor: "#243549" }}>
        <div
          className="container featured-area-default padding-top-20 padding-bottom-20"
          style={{ backgroundColor: "#243549" }}
        >
          <div className="row">
            {/* sidebar tuf */}
            <div className="col-md-3 margin-bottom-30">
              {/* Bagian pertama */}
              <div className="row margin-top-10">
                <div className="col-md-12">
                  <div
                    className="fat-content-small shadowku text-center"
                    style={{ backgroundColor: "#fff" }}
                  >
                    <a
                      href="/"
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        fontFamily: "'Roboto Condensed', sans-serif",
                        color: "#305f97",
                        textDecoration: "none",
                      }}
                    >
                      Technopedia Center
                    </a>
                  </div>
                </div>
              </div>

              {/* Bagian kedua */}
              <div className="row margin-top-10">
                <div className="col-md-12">
                  <div
                    className="fat-content-small shadowku text-center mb-2"
                    style={{ backgroundColor: "#ffe61b" }}
                  >
                    <a
                      href="https://spmb.teknokrat.ac.id/brosur-juara/"
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        fontFamily: "'Roboto Condensed', sans-serif",
                        color: "#305f97",
                        textDecoration: "none",
                      }}
                    >
                      PMB University Brochure
                    </a>
                  </div>
                </div>
              </div>

              {/* POPULAR TAGS (SHOW MAX 20 TAGS) */}
              <div className="row margin-top-10">
                <div className="col-md-12">
                  <div
                    className="fat-content-tags padding-left-5 shadowku text-center"
                    style={{ backgroundColor: "#fff" }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      Faculty of Engineering and Computer Science
                    </span>
                    <br />
                    <a
                      href="https://if.teknokrat.ac.id"
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontSize: "12px" }}
                    >
                      <i
                        className="fas fa-star"
                        style={{ color: "#305f97" }}
                      ></i>{" "}
                      S1 Informatics
                    </a>
                    <a
                      href="https://si.teknokrat.ac.id"
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontSize: "12px" }}
                    >
                      <i
                        className="fas fa-star"
                        style={{ color: "#305f97" }}
                      ></i>{" "}
                      S1 Information Systems
                    </a>
                    <a
                      href="https://ti.teknokrat.ac.id"
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontSize: "12px" }}
                    >
                      <i
                        className="fas fa-star"
                        style={{ color: "#305f97" }}
                      ></i>{" "}
                      S1 Information Technology
                    </a>
                    <a
                      href="https://tk.teknokrat.ac.id"
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontSize: "12px" }}
                    >
                      <i
                        className="fas fa-star"
                        style={{ color: "#305f97" }}
                      ></i>{" "}
                      S1 Computer Engineering
                    </a>
                    <a
                      href="https://te.teknokrat.ac.id"
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontSize: "12px" }}
                    >
                      <i
                        className="fas fa-star"
                        style={{ color: "#305f97" }}
                      ></i>{" "}
                      S1 Electrical Engineering
                    </a>
                    <a
                      href="https://ts.teknokrat.ac.id"
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontSize: "12px" }}
                    >
                      <i
                        className="fas fa-star"
                        style={{ color: "#305f97" }}
                      ></i>{" "}
                      S1 Civil Engineering
                    </a>

                    <br />
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      Faculty of Economics and Business
                    </span>
                    <br />
                    <a
                      href="https://m.teknokrat.ac.id"
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontSize: "12px" }}
                    >
                      <i
                        className="fas fa-star"
                        style={{ color: "#305f97" }}
                      ></i>{" "}
                      S1 Management
                    </a>
                    <a
                      href="https://ak.teknokrat.ac.id"
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontSize: "12px" }}
                    >
                      <i
                        className="fas fa-star"
                        style={{ color: "#305f97" }}
                      ></i>{" "}
                      S1 Accountancy
                    </a>
                    <br />
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      Faculty of Letters and Educational Sciences
                    </span>
                    <br />
                    <a
                      href="https://englishliterature.teknokrat.ac.id"
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontSize: "12px" }}
                    >
                      <i
                        className="fas fa-star"
                        style={{ color: "#305f97" }}
                      ></i>{" "}
                      S1 English Literature
                    </a>
                    <a
                      href="https://pbi.teknokrat.ac.id"
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontSize: "12px" }}
                    >
                      <i
                        className="fas fa-star"
                        style={{ color: "#305f97" }}
                      ></i>{" "}
                      S1 English Language Education
                    </a>
                    <a
                      href="https://pm.teknokrat.ac.id"
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontSize: "12px" }}
                    >
                      <i
                        className="fas fa-star"
                        style={{ color: "#305f97" }}
                      ></i>{" "}
                      S1 Mathematics Education
                    </a>
                    <a
                      href="https://po.teknokrat.ac.id"
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontSize: "12px" }}
                    >
                      <i
                        className="fas fa-star"
                        style={{ color: "#305f97" }}
                      ></i>{" "}
                      S1 Sports Education
                    </a>
                  </div>
                </div>
              </div>

              {/* Logo bagian bawah */}
              <div className="row margin-top-10">
                <div className="col-sm-12 text-center">
                  <a
                    href="https://teknokrat.ac.id"
                    style={{ backgroundColor: "#fff", width: "100%" }}
                    target="_blank"
                    rel="noreferrer"
                    className="btn p-2 shadowku"
                  >
                    <img
                      src={logoTekno}
                      alt="teknopedia"
                      style={{ width: "100%" }}
                    />
                  </a>
                </div>
                <br />
              </div>
              <div className="row">
                <div className="col-sm-12 text-center">
                  <a
                    href="https://teknokrat.ac.id/pkkmb2023/"
                    style={{ backgroundColor: "#fff" }}
                    target="_blank"
                    rel="noreferrer"
                    className="btn p-2 shadowku"
                  >
                    <img
                      src="https://teknokrat.ac.id/wp-content/uploads/2023/09/PKKMB-2.png"
                      alt="teknopedia"
                      style={{ width: "100%" }}
                    />
                  </a>
                </div>
                <br />
                <div className="col-sm-12 text-center">
                  <a
                    href="https://ftik.teknokrat.ac.id"
                    style={{ backgroundColor: "#fff", width: "100%" }}
                    target="_blank"
                    rel="noreferrer"
                    className="btn p-2 shadowku"
                  >
                    <img
                      src={logoFtik}
                      alt="teknopedia"
                      style={{ width: "100%" }}
                    />
                  </a>
                </div>
                <br />
                <div className="col-sm-12 text-center">
                  <a
                    href="https://feb.teknokrat.ac.id"
                    style={{ backgroundColor: "#fff", width: "100%" }}
                    target="_blank"
                    rel="noreferrer"
                    className="btn p-2 shadowku"
                  >
                    <img
                      src={logoFeb}
                      alt="teknopedia"
                      style={{ width: "100%" }}
                    />
                  </a>
                </div>
                <br />
                <div className="col-sm-12 text-center">
                  <a
                    href="https://fsip.teknokrat.ac.id"
                    style={{ backgroundColor: "#fff", width: "100%" }}
                    target="_blank"
                    rel="noreferrer"
                    className="btn p-2 shadowku"
                  >
                    <img
                      src={logoFsip}
                      alt="teknopedia"
                      style={{ width: "100%" }}
                    />
                  </a>
                </div>
                <br />
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 margin-top-10">
                  <a
                    href="https://seputarbisnis.teknokrat.ac.id"
                    style={{ backgroundColor: "#fff" }}
                    target="_blank"
                    rel="noreferrer"
                    className="btn p-2 shadowku"
                  >
                    <img
                      src={logoSeputarBisnis}
                      alt="teknopedia"
                      style={{ width: "100%" }}
                    />
                  </a>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 margin-top-10">
                  <a
                    href="https://tekno.teknokrat.ac.id"
                    style={{ backgroundColor: "#fff" }}
                    target="_blank"
                    rel="noreferrer"
                    className="btn p-2 shadowku"
                  >
                    <img
                      src={logoSeputarBisnis}
                      alt="teknopedia"
                      style={{ width: "100%" }}
                    />
                  </a>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 margin-top-10">
                  <a
                    href="https://yukbaca.teknokrat.ac.id"
                    style={{ backgroundColor: "#fff" }}
                    target="_blank"
                    rel="noreferrer"
                    className="btn p-2 shadowku"
                  >
                    <img
                      src={logoYukbaca}
                      alt="teknopedia"
                      style={{ width: "100%" }}
                    />
                  </a>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 margin-top-10">
                  <a
                    href="https://belajarbahasa.teknokrat.ac.id"
                    style={{ backgroundColor: "#fff" }}
                    target="_blank"
                    rel="noreferrer"
                    className="btn p-2 shadowku"
                  >
                    <img
                      src={logoBelajarBahasa}
                      alt="teknopedia"
                      style={{ width: "100%" }}
                    />
                  </a>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 margin-top-10">
                  <a
                    href="https://seputarbisnis.teknokrat.ac.id"
                    style={{ backgroundColor: "#fff" }}
                    target="_blank"
                    rel="noreferrer"
                    className="btn p-2 shadowku"
                  >
                    <img
                      src={logoSeputarBisnis}
                      alt="teknopedia"
                      style={{ width: "100%" }}
                    />
                  </a>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 margin-top-10">
                  <a
                    href="https://tekno.teknokrat.ac.id"
                    style={{ backgroundColor: "#fff" }}
                    target="_blank"
                    rel="noreferrer"
                    className="btn p-2 shadowku"
                  >
                    <img
                      src={logoSeputerTeknologi}
                      alt="teknopedia"
                      style={{ width: "100%" }}
                    />
                  </a>
                </div>
              </div>

              {/* Sisa konten */}
              {/* Silakan tambahkan komponen lain sesuai struktur yang sama */}
            </div>
            {/* end sidebar tuf */}
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  {/* BREADCRUMBS */}
                  <div
                    className="breadcrumb-container shadowku"
                    style={{ borderRadius: "5px", border: "1px solid #ffffff" }}
                  >
                    <ol className="breadcrumb" style={{ margin: "0px" }}>
                      <li>
                        <a href="https://en.teknopedia.teknokrat.ac.id">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-house"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z"></path>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://en.teknopedia.teknokrat.ac.id"
                          className="active"
                          target="_blank"
                          rel="noreferrer"
                        >
                          World Encyclopedia
                        </a>
                      </li>
                      <li>
                        <a
                          href="/#"
                          target="_blank"
                          rel="noreferrer"
                          className="active"
                        >
                          {title}
                        </a>
                      </li>
                    </ol>
                  </div>

                  <div
                    style={{
                      marginBottom: "20px",
                      borderRadius: "5px",
                      border: "1px solid #fff",
                      padding: "10px",
                      display: "none",
                    }}
                  >
                    <form id="url-form" className="form-inline">
                      <div className="row">
                        <div className="col-sm-10">
                          <input
                            type="text"
                            id="url_input"
                            required
                            name="search_query"
                            style={{
                              width: "100%",
                              height: "40px",
                              fontSize: "18px",
                            }}
                            className="form-control"
                            placeholder="Here you will find everything"
                          />
                        </div>
                        <div className="col-sm-2">
                          <button
                            type="submit"
                            style={{
                              width: "100%",
                              height: "40px",
                              fontSize: "18px",
                            }}
                            className="btn btn-primary mb-2"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>

                  {/* ENDBREADCRUMBS */}
                  {/* Article */}
                  <div
                    className="panel panel-default shadowku"
                    id="portal-utama"
                  >
                    <div className="article-heading margin-bottom-5">
                      <Link to="/">{title}</Link>
                    </div>
                    <div className="article-content text-justify">
                      {loading ? <i>Loading artikel ....</i> : content}
                    </div>
                  </div>
                  {/* end Article */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* footer */}
      <div className="container-fluid footer marg30">
        <div className="container">
          <div className="col-xs-12 col-sm-12 col-md-12 margin-top-20">
            <div className="panel-transparent text-center">
              <div className="footer-heading" style={{ fontSize: "24px" }}>
                Pusat Layanan
              </div>
              <div className="footer-body">
                <p style={{ fontSize: "20px" }}>
                  <a href="https://teknokrat.ac.id" style={{ color: "#fff" }}>
                    UNIVERSITAS TEKNOKRAT INDONESIA
                  </a>
                  {" | "}
                  <a href="https://teknokrat.ac.id" style={{ color: "#fff" }}>
                    ASEAN's Best Private University
                  </a>
                  <br />
                  Jl. ZA. Pagar Alam No.9 -11, Labuhan Ratu, Kec. Kedaton, Kota
                  Bandar Lampung, Lampung 35132
                  <br />
                  Phone: (0721) 702022
                  <br />
                  Email: pmb@teknokrat.ac.id
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end footer */}
    </>
  );
}
